export const breakpointValues = {
  xs: 0,
  sm: 600,
  mt: 800,
  md: 960,
  lg: 1280,
  lgx: 1380,
  mid: 1588,
  xl: 1920,
  xxl: 1921,
};

export const breakpoints = {
  sm: `(max-width: ${breakpointValues.sm}px)`,
  md: `(max-width: ${breakpointValues.md}px)`,
  mt: `(max-width: ${breakpointValues.mt}px)`,
  lg: `(max-width: ${breakpointValues.lg}px)`,
  lgx: `(max-width: ${breakpointValues.lgx}px)`,
  mid: `(max-width: ${breakpointValues.mid}px)`,
  xl: `(max-width: ${breakpointValues.xl}px)`,
  xxl: `(min-width: ${breakpointValues.xxl}px)`,
};
