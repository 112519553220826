import { useEffect, useState } from 'react';
import { breakpointValues } from '~/theme/breakpoints';

function useMediaQuery() {
  const [windowSize, setWindowSize] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowSize <= breakpointValues.sm;
  const isTablet = windowSize <= breakpointValues.md;
  const isTabletLg = windowSize <= breakpointValues.lg;
  const isTabletLgx = windowSize <= breakpointValues.lgx;
  const isDesktop = windowSize <= breakpointValues.xl;
  const isDesktopLg = windowSize >= breakpointValues.xxl;

  return {
    windowSize,
    isMobile,
    isTablet,
    isTabletLg,
    isTabletLgx,
    isDesktop,
    isDesktopLg,
  };
}

export default useMediaQuery;
